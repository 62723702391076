import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import config from 'devextreme/core/config';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { licenseKey } from './devextreme-license';
import { environment } from './environments/environment';

config({ licenseKey });

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
