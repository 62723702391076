import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbOffcanvas, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from 'src/app/core/auth/services';
import { AppStateService } from 'src/app/core/auth/services/app-state.service';
import { GraphqlService } from 'src/app/core/graphql/services';
import { IconsModule } from 'src/app/core/icons/icons.module';
import { CompanyType } from 'src/app/shared/constants/company-type.constant';
import { Company, User } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { NavbarNavComponent } from './navbar-nav/navbar-nav.component';

@Component({
	selector: 'engagement-navbar',
	standalone: true,
	imports: [CommonModule, RouterModule, IconsModule, NavbarNavComponent, NgbOffcanvasModule, NgbDropdownModule],
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
	public navbarClass: string;

	public user: User;
	public companies: Company[];
	public environment = environment;
	public loading = true;
	public defaultCompany: Company;

	constructor(
		private authService: AuthService,
		private graphqlService: GraphqlService,
		private appStateService: AppStateService,
		private offcanvasService: NgbOffcanvas,
	) {}

	get loggedInUser() {
		return this.authService.loggedInUser;
	}

	get isSystem() {
		return this.loggedInUser?.isSystem;
	}

	async ngOnInit(): Promise<void> {
		this.appStateService.onUserUpdated.subscribe(async () => {
			this.loading = true;
			await this.getNavbarData();
			this.loading = false;
		});

		if (this.loggedInUser) {
			await this.getNavbarData();
		}

		this.loading = false;
	}

	private async getNavbarData(): Promise<void> {
		const result = await this.graphqlService.query<{ user: User; companies: { node: Company[] } }>(
			`
			query GetNavbarData($id: Int!) {
				user(id: $id) {
					id
					email
					defaultNavbarLogo {
						base64 {
							prefix
							data
						}
					}
				}
				companies(order: [["name"]]) {
					node {
						id
						name
					}
				}
			}
		`,
			{ id: this.loggedInUser.id },
		);

		this.user = result.data.user;
		this.companies = result.data.companies.node;
		this.defaultCompany = this.companies.find(c => c.id === CompanyType.powerkiosk);
		this.appStateService.setDefaultCompanyId(this.defaultCompany?.id || CompanyType.powerkiosk, false);
	}

	public async openCanvas(content: TemplateRef<any>): Promise<void> {
		this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvasNavbarLabel', position: 'end' });
	}

	public chooseDefaultCompany(company: Company): void {
		this.defaultCompany = company;
		this.appStateService.setDefaultCompanyId(company.id);
	}
}
