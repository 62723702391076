import { Routes } from '@angular/router';

import { AuthResolver } from './core/resolvers/auth.resolver';

export const routes: Routes = [
	{
		path: 'admin',
		loadChildren: () => import('./features/admin/admin.routes'),
		runGuardsAndResolvers: 'always',
		resolve: { auth: AuthResolver },
		data: { isSystem: true, isAdmin: true },
	},
	{
		path: 'auth',
		loadChildren: () => import('./core/auth/auth.routes'),
	},
	{
		path: 'user',
		loadChildren: () => import('./features/user/user.routes'),
		resolve: { auth: AuthResolver },
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('./features/privacy-policy/privacy-policy.routes'),
		resolve: { auth: AuthResolver },
	},
	{
		path: 'terms-of-service',
		loadChildren: () => import('./features/terms-of-service/terms-of-service.routes'),
		resolve: { auth: AuthResolver },
	},
	{
		path: 'engagements',
		loadChildren: () => import('./features/engagements/engagements.routes'),
		resolve: { auth: AuthResolver },
		data: { preload: true },
	},
	{
		path: 'emails',
		loadChildren: () => import('./features/emails/emails.routes'),
		resolve: { auth: AuthResolver },
	},
	{
		path: '',
		redirectTo: 'admin',
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: '',
	},
];
