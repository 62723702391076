<footer>
	<div class="container-fluid">
		<div class="row d-flex justify-content-center">
			<div class="col-auto">
				<p>
					<a routerLinkActive="active" href="javascript:void(0)" class="gray-link reverse-deco disabled">Terms of Service</a
					><span class="font-size-15 mx-2">&#8226;</span
					><a routerLinkActive="active" href="javascript:void(0)" class="gray-link reverse-deco disabled">Privacy Policy</a>
				</p>
			</div>
		</div>
	</div>
</footer>
