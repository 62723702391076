<ul class="navbar-nav ms-auto">
	@if (isAdminOrSystem) {
		<li ngbDropdown class="nav-item d-inline d-lg-none">
			<a ngbDropdownToggle class="nav-link" href="javascript:void(0);" role="button" aria-expanded="false">Engagements</a>
			<ul ngbDropdownMenu>
				<li class="mb-3"><a ngbDropdownItem [routerLink]="['/engagements', 'overview']" routerLinkActive="active">Overview</a></li>
				<li class="mb-3"><a ngbDropdownItem [routerLink]="['/engagements', 'triggers']" routerLinkActive="active">Triggers</a></li>
				<li class="mb-3"><a ngbDropdownItem [routerLink]="['/engagements', 'queries']" routerLinkActive="active">Queries</a></li>
				<li class="mb-3"><a ngbDropdownItem [routerLink]="['/engagements', 'data-sources']" routerLinkActive="active">Data Sources</a></li>
			</ul>
		</li>
	} @else {
		<li class="nav-item d-inline d-lg-none">
			<a ngbDropdownItem [routerLink]="['/engagements', 'overview']" routerLinkActive="active">Engagements</a>
		</li>
	}
	<li class="nav-item d-inline d-lg-none">
		<a class="nav-link disabled" routerLinkActive="active" href="javascript:void(0)">Emails</a>
	</li>
	<li class="nav-item">
		<a class="nav-link disabled" href="javascript:void(0)"><fa-icon [icon]="['fas', 'circle-question']"></fa-icon>Support</a>
	</li>
	<li ngbDropdown class="nav-item">
		<a ngbDropdownToggle class="nav-link" href="javascript:void(0);" role="button" aria-expanded="false"
			><fa-icon [icon]="['fas', 'gear']" [fixedWidth]="true"></fa-icon>Settings</a
		>
		<ul ngbDropdownMenu class="dropdown-menu-lg-end">
			@if (isAdminOrSystem) {
				<li class="mb-3">
					<a ngbDropdownItem [routerLink]="['admin']" routerLinkActive="active"
						><fa-icon [icon]="['fas', 'gears']" [fixedWidth]="true"></fa-icon>Admin</a
					>
				</li>
			}
			<li class="mb-3">
				<a ngbDropdownItem [routerLink]="['user']" routerLinkActive="active"
					><fa-icon [icon]="['fas', 'user']" [fixedWidth]="true"></fa-icon>Account</a
				>
			</li>
			<li class="mb-3">
				<a ngbDropdownItem (click)="logout()" href="javascript:void(0)"
					><fa-icon [icon]="['fas', 'arrow-right-from-bracket']" [fixedWidth]="true"></fa-icon>Logout</a
				>
			</li>
		</ul>
	</li>
</ul>
