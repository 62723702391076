import { ApplicationConfig } from '@angular/core';

import { AssigneesFilterPipe } from '../pipes/assignees-filter.pipe';
import { EventDataSourceFilterPipe } from '../pipes/event-data-source-filter.pipe';
import { EventTriggerFilterPipe } from '../pipes/event-trigger-filter.pipe';
import { ViewEventsFilterPipe } from '../pipes/view-events-filter.pipe';
import { ViewEventsSortPipe } from '../pipes/view-events-sort.pipe';

export const pipesProvider: ApplicationConfig['providers'] = [
	ViewEventsSortPipe,
	EventDataSourceFilterPipe,
	EventTriggerFilterPipe,
	ViewEventsFilterPipe,
	AssigneesFilterPipe,
];
