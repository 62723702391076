import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, map, Observable, switchMap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthFields } from '../auth/models';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const operationName = req.body && req.body.operationName ? req.body.operationName : '';
		const isRefresh = operationName.includes('RefreshToken');
		const excludeOperationNames = ['Login'];

		return from(this.authService.getAuthFields(isRefresh)).pipe(
			switchMap(authFields => {
				const token = this.getToken(authFields, isRefresh);
				const clonedRequest = req.clone({
					headers: req.headers.set('Authorization', 'Bearer ' + token),
				});

				return next.handle(clonedRequest).pipe(
					map(event => {
						let unauthenticated = false;

						if (
							event.type === HttpEventType.Response &&
							event.status === 200 &&
							event.body &&
							Array.isArray(event.body.errors) &&
							!excludeOperationNames.includes(operationName)
						) {
							const errors = event.body.errors as any[];

							unauthenticated = errors.some(e => e.extensions && e.extensions.code === 'UNAUTHENTICATED');
						}

						if (!isRefresh && unauthenticated) {
							this.authService.logout();
						}

						return event;
					}),
				);
			}),
		);
	}

	private getToken(authFields: AuthFields, isRefresh: boolean): string {
		if (!authFields?.accessToken) {
			return environment.apiKey;
		}
		return isRefresh ? authFields.refreshToken : authFields.accessToken;
	}
}
