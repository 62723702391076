import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from 'src/app/core/auth/services';
import { IconsModule } from 'src/app/core/icons/icons.module';

@Component({
	selector: 'engagement-navbar-nav',
	standalone: true,
	imports: [CommonModule, NgbDropdownModule, IconsModule, RouterModule],
	templateUrl: './navbar-nav.component.html',
	styleUrl: './navbar-nav.component.scss',
})
export class NavbarNavComponent {
	@Output() navigated: EventEmitter<boolean> = new EventEmitter();

	constructor(private authService: AuthService) {}

	get loggedInUser() {
		return this.authService.loggedInUser;
	}

	get isAdminOrSystem() {
		return this.loggedInUser.isAdmin || this.loggedInUser.isSystem;
	}

	public logout(): void {
		this.authService.logout();
	}
}
